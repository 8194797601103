import React from 'react';
import ReactDOM from 'react-dom';
import BuyCounter from '../components/buyCounter';

// Environment
try {
  window.environment = process.env.RAILS_ENV;
} catch (err) {
  console.log('Could not set environment');
  console.log(err.message);
}

window.mountComponents = () => {
  document.querySelectorAll('.react-buy-counter').forEach((element) => {
    ReactDOM.render(
      React.createElement(BuyCounter, JSON.parse(element.dataset.props)),
      element
    );
  });
};

const enableTooltips = () => {
  $("[data-toggle='tooltip'").tooltip({html: true})
}

if (Turbolinks.supported) {
  document.addEventListener("turbolinks:load", enableTooltips);
} else {
  $(document).ready(enableTooltips);
}
