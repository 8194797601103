import React, { useState } from 'react';

const MAX_VALUE = 99;
const MIN_VALUE = 0;

const BuyCounter = ({inputName, count}) => {
  const [itemCount, setItemCount] = useState(count || 0);

  const changeValue = (newValue) => {
    setItemCount(Math.min(MAX_VALUE, Math.max(MIN_VALUE, newValue)))
  }

  const increment = () => changeValue(itemCount + 1);
  const decrement = () => changeValue(itemCount - 1);

  const onChange = (e) => {
    const value = e.target.value || 1
    const number = Number.isNaN(value) ? itemCount : Number.parseInt(value);
    changeValue(number);
  }

  return (
    <>
      <input type="hidden" name={inputName} value={itemCount} />
      {itemCount <= 0 ? (
        <button
          type="button"
          className="btn btn-success"
          onClick={() => changeValue(1)}
        >
          {document.documentElement.lang == 'en' ? 'Buy' : 'Chci koupit'}
        </button>
      ) : (
        <div className="buy-counter-button-holder">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={decrement}
          >
            -
          </button>
          <input type="number" onChange={onChange} value={itemCount} />
          <button type="button" className="btn btn-primary" onClick={increment}>
            +
          </button>
        </div>
      )}
    </>
  );
};

export default BuyCounter;
